const userRoutes = [
	{
		path: '/',
		name: '/',
		redirect: '/sample',
		meta: {
			title: "医疗管理系统"
		}
	},
	{
		path: '/login',
		name: 'Login',
		component: () => import(/* webpackChunkName: "user" */ '@/pages/Login'),
		meta: {
			title: "登录"
		}
	},
	{
		path: '/user',
		name: 'User',
		component: () => import(/* webpackChunkName: "user" */ '@/pages/User'),
		meta: {
			title: "用户中心"
		}
	},
	{
		path: '/sample',
		name: 'Sample',
		component: () => import(/* webpackChunkName: "user" */ '@/pages/Sample'),
		meta: {
			title: "采样"
		}
	},
	{
		path: '/sample/camera',
		name: 'SampleCamera',
		component: () => import(/* webpackChunkName: "user" */ '@/pages/SampleCamera'),
		meta: {
			title: "采样扫描"
		}
	},
	{
		path: '/sample/info',
		name: 'SampleInfo',
		component: () => import(/* webpackChunkName: "user" */ '@/pages/SampleInfo'),
		meta: {
			title: "采样信息"
		}
	},
	{
		path: '/print',
		name: 'Print',
		component: () => import(/* webpackChunkName: "user" */ '@/pages/Print'),
		meta: {
			title: "条形码打印"
		}
	},
]

const routes = [...userRoutes];

export default routes;