import Vue from 'vue'
import Router from 'vue-router'
import routes from './routes.js'
// import {oauth_config } from '../config.js';
// import {get_param} from '../utils/auth.js';
// import request from '../utils/request.js';
// import store from '../store/index.js';

Vue.use(Router) // Vue 全局使用Router

const router = new Router({
	mode: 'history',
	routes
})

router.beforeEach((to, from, next) => {
	const token = localStorage.getItem("access-token");

	if (token) {
		// request.get('/user/profile')
		// .then(res => {
		// 	console.log(res.payload)
		// 	store.dispatch('stopGlobalSpinning');
		// 	store.dispatch('updateUserInfo', res.payload);

		// 	if (to.path.split('/')[1] === 'admin' && res.payload.privilege == 0) {
		// 		next({path: '/admin_warning'});
		// 	}

		// 	next();
		// })
		// .catch(err => {
		// 	console.log(err);
		// })

		next();
	} else {
		if (to.path === '/login') {
			next();
		} else {
			next('/login');
		}
	}
})

export default router;

